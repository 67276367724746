import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  constructor() { }

  ctx;

  ngOnInit() {
  let clientX = -100;
	let clientY = -100;
	const innerCursor = <HTMLElement>document.querySelector(".cursor--small");

	const initCursor = () => {
	  document.addEventListener("mousemove", e => {
	    clientX = e.clientX;
	    clientY = e.clientY;
	  });

	  const render = () => {
	    innerCursor.style.transform = `translate(${clientX}px, ${clientY}px)`;
	    requestAnimationFrame(render);
	  };
	  requestAnimationFrame(render);
	};

	initCursor();

	let lastX = 0;
	let lastY = 0;
	let isStuck = false;
	let showCursor = false;
	let group, stuckX, stuckY, fillOuterCursor;

	const initCanvas = () => {
	    const canvas = document.querySelector(".cursor--canvas");
	    const shapeBounds = {
	      width: 75,
	      height: 75
	    };
	    paper.setup(canvas);
	    const strokeColor = "rgba(255, 0, 0, 0.5)";
	    const strokeWidth = 2;
	    const segments = 8;
	    const radius = 17;
	  
	    const noiseScale = 150; // speed
	    const noiseRange = 4; // range of distortion
	    let isNoisy = false; // state
	  
	    // the base shape for the noisy circle
	    const polygon = new paper.Path.RegularPolygon(
	      new paper.Point(0, 0),
	      segments,
	      radius
	    );
	    polygon.strokeColor = strokeColor;
	    polygon.strokeWidth = strokeWidth;
	    polygon.smooth();
	    group = new paper.Group([polygon]);
	    group.applyMatrix = false;
	  
	    const noiseObjects = polygon.segments.map(() => null);
	    let bigCoordinates = [];
	  
	    // function for linear interpolation of values
	    const lerp = (a, b, n) => {
	      return (1 - n) * a + n * b;
	    };
	  
	    // function to map a value from one range to another range
	    const map = (value, in_min, in_max, out_min, out_max) => {
	      return (
	        ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
	      );
	    };
	  
	    paper.view.onFrame = event => {
		    lastX = lerp(lastX, clientX, 0.2);
		    lastY = lerp(lastY, clientY, 0.2);
		    group.position = new paper.Point(lastX, lastY);
	    }
	}

    initCanvas();

    function update(data) {
    	const pos = 1 - ((data.max - data.scrolled) / data.max);
    	bar.style.transform = `scale(${pos}, 1)`;
    	var that = this
    }

    function colorChange(data){
    	var that = this
    }

    const bar = <HTMLElement>document.querySelector(".bar")
 
    const pages = new Pageable("#example",{
	    pips: true,
	    animation: 800,
	    delay: 800,
	    throttle: 50,
	    swipeThreshold: 1000,
	    orientation: "vertical", 
	    slideshow: false,
	    onScroll: update,
	    onFinish: colorChange
	});

    /*** SPACE BACKGROUND */
    var width, height, largeHeader, canvas, points, target;
    var animateHeader = true;


    var initHeader = () => {
        width = window.innerWidth;
        height = window.innerHeight;
        target = {x: width/2, y: height/2};

        largeHeader = document.getElementById('large-header');
        largeHeader.style.height = height+'px';

        canvas = document.getElementById('demo-canvas');
        canvas.width = width;
        canvas.height = height;
        this.ctx = canvas.getContext('2d');

        // create points
        points = [];
        for(var x = 0; x < width; x = x + width/20) {
            for(var y = 0; y < height; y = y + height/20) {
                var px = x + Math.random()*width/20;
                var py = y + Math.random()*height/20;
                var p = {x: px, originX: px, y: py, originY: py };
                points.push(p);
            }
        }

        // for each point find the 5 closest points
        for(var i = 0; i < points.length; i++) {
            var closest = [];
            var p1 = points[i];
            for(var j = 0; j < points.length; j++) {
                var p2 = points[j]
                if(!(p1 == p2)) {
                    var placed = false;
                    for(var k = 0; k < 5; k++) {
                        if(!placed) {
                            if(closest[k] == undefined) {
                                closest[k] = p2;
                                placed = true;
                            }
                        }
                    }

                    for(var k = 0; k < 5; k++) {
                        if(!placed) {
                            if(getDistance(p1, p2) < getDistance(p1, closest[k])) {
                                closest[k] = p2;
                                placed = true;
                            }
                        }
                    }
                }
            }
            p1.closest = closest;
        }

        // assign a circle to each point
        for(var iq in points) {
        	var that = this;
            var c = new Circle(points[iq], 2+Math.random()*2, 'rgba(255,255,255,0.3)', this);
            points[iq].circle = c;
        }
    }

    var addListeners = () => {
        //if(!('ontouchstart' in window)) {
            window.addEventListener('mousemove', mouseMove);
       // }
        window.addEventListener('scroll', scrollCheck);
        window.addEventListener('resize', resize);
    }

    var mouseMove = (e) => {
        var posx, posy = 0;
        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        }
        else if (e.clientX || e.clientY)    {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        target.x = posx;
        target.y = posy;
    }

    var scrollCheck = () => {
        if(document.body.scrollTop > height) animateHeader = false;
        else animateHeader = true;
    }

    var resize = () => {
        width = window.innerWidth;
        height = window.innerHeight;
        largeHeader.style.height = height+'px';
        canvas.width = width;
        canvas.height = height;
    }

    var initAnimation = () =>  {
        animate();
        for(var i in points) {
            shiftPoint(points[i]);
        }
    }

    var animate = () => {
        if(animateHeader) {
            this.ctx.clearRect(0,0,width,height);
            for(var i in points) {
                // detect points in range
                if(Math.abs(getDistance(target, points[i])) < 4000) {
                    points[i].active = 0.3;
                    points[i].circle.active = 0.6;
                } else if(Math.abs(getDistance(target, points[i])) < 20000) {
                    points[i].active = 0.1;
                    points[i].circle.active = 0.3;
                } else if(Math.abs(getDistance(target, points[i])) < 40000) {
                    points[i].active = 0.02;
                    points[i].circle.active = 0.1;
                } else {
                    points[i].active = 0;
                    points[i].circle.active = 0;
                }

                drawLines(points[i]);
                points[i].circle.draw();
            }
        }
        requestAnimationFrame(animate);
    }


    var shiftPoint = (p) => {
        TweenLite.to(p, 1+1*Math.random(), {x:p.originX-50+Math.random()*100,
            y: p.originY-50+Math.random()*100, ease:Circ.easeInOut,
            onComplete: function() {
                shiftPoint(p);
            }});
    }


    var drawLines = (p) => {
        if(!p.active) return;
        for(var i in p.closest) {
            this.ctx.beginPath();
            this.ctx.moveTo(p.x, p.y);
            this.ctx.lineTo(p.closest[i].x, p.closest[i].y);
            this.ctx.strokeStyle = 'rgba(156,217,249,'+ p.active+')';
            this.ctx.stroke();
        }
    }

    var getDistance = (p1, p2) => {
        return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    }

    initHeader();
    initAnimation();
    addListeners();
  }
}

export class Circle{

		pos;
		radius;
		color;
		analyticsHome;
		active = 0;
       
       constructor(pos,rad,color, analyticsHome) {
            this.pos = pos || null;
            this.radius = rad || null;
            this.color = color || null;
            this.analyticsHome = analyticsHome;
        };

        draw() {
            if(!this.active) return;
	        this.analyticsHome.ctx.beginPath();
	        this.analyticsHome.ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
	        this.analyticsHome.ctx.fillStyle = 'rgba(156,217,249,'+ this.active+')';
	        this.analyticsHome.ctx.fill();
        };
}


    

