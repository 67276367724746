<div class="homecomponentwrapper">

	<div class="cursor cursor--small"></div>
	<canvas class="cursor cursor--canvas" resize></canvas>
	<!-- <div class="linear-progress">
		<div class="bar"></div>
    </div> -->


	  <!-- <section class="section1" data-anchor="Page 1"> -->
	    <div class="container demo">
		   <div class="content">
		      <div id="large-header" class="large-header">
                 <canvas id="demo-canvas"></canvas>
                 <img class="main-title logo" src="/assets/img/vizion.png">
		         <h1 class="main-title"> Entertainment Revolution <span class="thin">  Through Artificial Intelligence </span></h1>
                 <p class="contact"> industry@vizion.app</p> 
                </div>
		   </div>

		   <div>
				<div class="split left">
					<h1 class="segmenttext"> One Platform <span class="thin"> For All Your Needs </span></h1>
				</div>
				
				<div class="split right">
					<div class="centered">
						<img src="/assets/img/d1.jpg">
					</div>
				</div>
		   </div>

		   <div>
				<div class="split left2">
					<div class="centered">
						<img src="/assets/img/d2.jpg">
					</div>
				</div>
				<div class="split right2">
					<h1 class="segmenttext2"> Automaticaly <span class="thin">   Optimized by Data  </span></h1>
				</div>
			</div>

			<div>
				<div class="split left3">
					<div class="centered">
						<h1 class="segmenttext3"> Take Control Of Your Career </h1>
					</div>
				</div>
				<div class="split right4">
					<img class="smallrecimg" src="/assets/img/projects.jpg">
					<img  class="smallrecimg2" src="/assets/img/writing.jpg"> 
				</div>
			</div>

			<div class="arg">
				<div class="split left2">
					<div class="centered">
						<img src="/assets/img/vd.jpg">
					</div>
				</div>
				<div class="split right2">
					<h1 class="segmenttext4"> Book tours & manage your shows, merch and VIP experiences  </h1>
				</div>
			</div>

			<div class="arg2">
				<div class="split left2">
					<div class="centered">
						<img src="/assets/img/studio.jpg">
					</div>
				</div>
				<div class="split right2">
					<h1 class="segmenttext5"> Connect with the best professionals based on your genre, budget and schedule </h1>
				</div>
			</div>
			
			<div class="arg2">
				<div class="split left3">
					<div class="centered">
						<h1 class="segmenttext3"> Promote Your Work To The Right Media </h1>
					</div>
				</div>
				<div class="split right4">
					<img class="smallrecimgx1" src="/assets/img/campaign.jpg">
					<img  class="smallrecimgx2" src="/assets/img/publications.jpg"> 
					<img  class="smallrecimgx3" src="/assets/img/analytics.jpg"> 

					<h1 class="segmenttext3"> Get In Touch Now! </h1>
					<p style="text-align: center; color: wheat; font-size: 10px;"> All Rights Reserved © vizion.app</p>
				</div>
			</div>


	
		</div>
		
	  <!-- </section> -->
	
</div>