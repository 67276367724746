<div class="gorilla-banner">
</div>

<div class="tos">
	<h2> VaryIt Privacy Policy </h2>
	<p>We believe you should always know what data we collect from you and how we use it, and that you should have meaningful control over both. We want to empower you to make the best decisions about the information that you share with us.
That’s the basic purpose of this Privacy Policy.
You should read this policy in full, but here are a few key things we hope you take away from it:
When you use VaryIt we receive some personal information from you like the type of device you’re using to get better insights into our users and improve our products. Should you create segments through VaryIt, your store ID, your customer info and orders will be backed up in our database. Should you uninstall the app, your data will be deleted in accordance with Shopify's app privacy guidelines. The only information that is permanent is your store ID, which we use to track who used their Free Trials.
You can also always review the information you have shared. 
If you have questions about this policy, how we collect or process your personal data, or anything else related to our privacy practices, we want to hear from you. You can contact us at any time. </p>


  <h3> Information That You Share With Us </h3>
  <p> We require certain information to provide our services to you. 

 <h5> Public Information </h5>
 <p> None of the information you upload to VaryIt or information collected by the app is public. All information is encrypted and stored securely. 
 </p>

 <h5> Contact Information and Address Books </h5>
 <p>We use your contact information, such as your email address or phone number, to authenticate your account and keep it - and our services - secure, and to help prevent spam, fraud, and abuse. We also use contact information to personalize our services, enable certain account features, and to send you information about our services. If you provide us with your phone number, you agree to receive text messages from Vizion to that number as your country’s laws allow. Vizion also uses your contact information to market to you as your country’s laws allow, and to help others find your account if your settings permit, including through third-party services and client applications. You can use your settings for email and mobile notifications to control notifications you receive from Vizion. 
If you email us, we will keep the content of your message, your email address, and your contact information to respond to your request.
</p>

 <h3> Additional Information We Receive About You </h3>
 <p> We receive certain information when you use our services or other websites or mobile applications that include our content, and from third parties including advertisers. Like the information you share with us, we use the data below to operate our services.
</p>


<h5> Location Information </h5>
<p> We require information about your signup and current location, which we get from signals such as your IP address or device settings, to securely and reliably set up and maintain your account and to provide our services to you.
Subject to your settings, we may collect, use, and store additional information about your location - such as your current precise position or places where you’ve previously used Vizion - to operate or personalize our services including with more relevant content like local shows, ads, and suggestions for people to follow. </p>

<h5> Links </h5>
<p> In order to operate our services, we keep track of how you interact with links across our services. This includes links in emails we send you and links in uploads that appear on other websites or mobile applications.
If you click on an external link or ad on our services, that advertiser or website operator might figure out that you came from Vizion, along with other information associated with the ad you clicked such as characteristics of the audience it was intended to reach. They may also collect other personal data from you, such as cookie identifiers or your IP address.
</p>

<h5> Cookies </h5>
<p> A cookie is a small piece of data that is stored on your computer or mobile device. Like many websites, we use cookies and similar technologies to collect additional website usage data and to operate our services. Cookies are not required for many parts of our services such as searching and looking at public profiles. Although most web browsers automatically accept cookies, many browsers’ settings can be set to decline cookies or alert you when a website is attempting to place a cookie on your computer. However, some of our services may not function properly if you disable cookies. When your browser or device allows it, we use both session cookies and persistent cookies to better understand how you interact with our services, to monitor aggregate usage patterns, and to personalize and otherwise operate our services such as by providing account security, personalizing the content we show you including ads, and remembering your language preferences. We do not support the Do Not Track browser option. You can learn more about how we use cookies and similar technologies by contacting us.
 </p>

 <h5> Log Data </h5>
 <p> We receive information when you view content on or otherwise interact with our services, which we refer to as “Log Data,” even if you have not created an account. For example, when you visit our websites, sign into our services, interact with our email notifications, use your account to authenticate to a third-party service, or visit a third-party service that includes Vizion content, we may receive information about you. This Log Data includes information such as your IP address, browser type, operating system, the referring web page, pages visited, location, your mobile carrier, device information (including device and application IDs), search terms, and cookie information. We also receive Log Data when you click on, view, or interact with links on our services, including when you install another application through Vizion. We use Log Data to operate our services and ensure their secure, reliable, and robust performance. For example, we use Log Data to protect the security of accounts and to determine what content is popular on our services. We also use this data to improve the content we show you.
We use information you provide to us and data we receive, including Log Data and data from third parties, to make inferences like what topics you may be interested in, how old you are, and what languages you speak. This helps us better design our services for you and personalize the content we show you.
</p>

<h5> Vizion For Web Data </h5>
<p> When you view our content on third-party websites that integrate Vizion content, we may receive Log Data that includes the web page you visited. We use this information to better understand the use of our services, to protect the safety and integrity of our platform, and to show more relevant content, including ads. We do not associate this web browsing history with your name, email address, phone number, or username, and we delete, obfuscate, or aggregate it after no longer than 30 days. We do not collect this data from browsers that we believe to be located in the European Union or EFTA States.
</p>

<h5> Advertisers And Other Ad Partners </h5>

<p> Advertising revenue allows us to support and improve our services. We use the information described in this Privacy Policy to help make our advertising more relevant to you, to measure its effectiveness, and to help recognize your devices to serve you ads on and off of Vizion. Our ad partners and affiliates share information with us such as browser cookie IDs, mobile device IDs, hashed email addresses, demographic or interest data, and content viewed or actions taken on a website or app. Some of our ad partners, particularly our advertisers, also enable us to collect similar information directly from their website or app by integrating our advertising technology.
Vizion adheres to the Digital Advertising Alliance Self-Regulatory Principles for Online Behavioral Advertising (also referred to as “interest-based advertising”) and respects the DAA’s consumer choice tool for you to opt out of interest-based advertising at https://optout.aboutads.info. In addition, our ads policies prohibit advertisers from targeting ads based on categories that we consider sensitive or are prohibited by law, such as race, religion, politics, sex life, or health. Learn more about your privacy options for interest-based ads here and about how ads work on our services here.
If you are an advertiser or a prospective advertiser, we process your personal data to help offer and provide our advertising services. You can update your data by contacting us directly as described in this Privacy Policy. </p>

<h5> Other Third Parties And Affiliate </h5>

<p> We may receive information about you from third parties who are not our ad partners, such as others on Vizion, partners who help us evaluate the safety and quality of content on our platform, our corporate affiliates, and other services you link to your Vizion account.
You may choose to connect your Vizion account to accounts on another service, and that other service may send us information about your account on that service. We use the information we receive to provide you features like cross-posting or cross-service authentication, and to operate our services. For integrations that Vizion formally supports, you may revoke this permission at any time from your application settings; for other integrations, please visit the other service you have connected to Vizion.
</p>

<h5> Personalizing Across Your Devices </h5>
<p> When you log into Vizion on a browser or device, we will associate that browser or device with your account for purposes such as authentication, security, and personalization. Subject to your settings, we may also associate your account with browsers or devices other than those you use to log into Vizion (or associate your logged-out device or browser with other browsers or devices). We do this to operate and personalize our services. 
</p>

<h3> Information We Share And Disclose </h3>
<p> As noted above, Vizion is designed to broadly and instantly disseminate information you share publicly through our services. In the limited circumstances where we disclose your private personal data, we do so subject to your control, because it’s necessary to operate our services, or because it’s required by law.
</p>

<h5> Sharing You Control </h5>
<p> We share or disclose your personal data with your consent or at your direction, such as when you authorize a third-party web client or application to access your account or when you direct us to share your feedback with a business. If you’ve shared information  with someone else who accesses Vizion through a third-party service, keep in mind that the information may be shared with the third-party service.
Subject to your settings, we also provide certain third parties with personal data to help us offer or operate our services. For example, we share with advertisers the identifiers of devices that saw their ads, to enable them to measure the effectiveness of our advertising business. We also share device identifiers, along with the interests or other characteristics of a device or the person using it, to help partners decide whether to serve an ad to that device or to enable them to conduct marketing, brand analysis, interest-based advertising, or similar activities. You can learn more about these partnerships in our Help Center, and you can control whether Vizion shares your personal data in this way by using the “Share your data with Vizion's business partners” option in your Personalization and Data settings. (This setting does not control sharing described elsewhere in our Privacy Policy, such as when we share data with our service providers.) The information we share with these partners does not include your name, email address, phone number, or Vizion username, but some of these partnerships allow the information we share to be linked to other personal information if the partner gets your consent first.
</p>

<h5> Service Providers </h5>
<p> We engage service providers to perform functions and provide services to us in the United States, Ireland, and other countries. For example, we use a variety of third-party services to help operate our services, such as hosting our various blogs and wikis, and to help us understand the use of our services, such as Google Analytics. We may share your private personal data with such service providers subject to obligations consistent with this Privacy Policy and any other appropriate confidentiality and security measures, and on the condition that the third parties use your private personal data only on our behalf and pursuant to our instructions. We share your payment information with payment services providers to process payments; prevent, detect, and investigate fraud or other prohibited activities; facilitate dispute resolution such as chargebacks or refunds; and for other purposes associated with the acceptance of credit and debit cards.
</p>

<h5> Law, Harm, and the Public Interest </h5>
<p> Notwithstanding anything to the contrary in this Privacy Policy or controls we may otherwise offer to you, we may preserve, use, or disclose your personal data if we believe that it is reasonably necessary to comply with a law, regulation, legal process, or governmental request; to protect the safety of any person; to protect the safety or integrity of our platform, including to help prevent spam, abuse, or malicious actors on our services, or to explain why we have removed content or accounts from our services; to address fraud, security, or technical issues; or to protect our rights or property or the rights or property of those who use our services. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to a third party’s, including a government’s, request to disclose your personal data.
</p>

<h5> Affiliates and Change of Ownership </h5>

<p> In the event that we are involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your personal data may be sold or transferred as part of that transaction. This Privacy Policy will apply to your personal data as transferred to the new entity. We may also disclose personal data about you to our corporate affiliates in order to help operate our services and our affiliates’ services, including the delivery of ads.
</p>

<h5> Non Personal Information </h5>
<p> We share or disclose non-personal data, such as aggregated information like the total number of times people engaged with an item, the number of people who clicked on a particular link or reports to advertisers about how many people saw or clicked on their ads.
</p>

<h3> Managing Your Personal Information With Us </h3>
<p> You control the personal data you share with us. You can access or rectify this data at any time. You can also deactivate your account. We also provide you tools to object, restrict, or withdraw consent where applicable for the use of data you have provided to Vizion. And we make the data you shared through our services portable and provide easy ways for you to contact us.
</p>

<h5> Accessing or Rectifying Your Personal Data</h5> 
<p> If you have registered an account on Vizion, we provide you with tools and account settings to access, correct, delete, or modify the personal data you provided to us and associated with your account. You can download certain account information, including your uploads, by following the instructions here. You can learn more about the interests we have inferred about you in Your Vizion Data and request access to additional information here.
</p>

<h5> Deletion </h5>
<p> If you follow the instructions here, your account will be deactivated and then deleted. When deactivated, your Vizion account, including your display name, username, and public profile, will no longer be viewable on Vizion, Vizion for iOS, and Vizion for Android. For up to 30 days after deactivation it is still possible to restore your Vizion account if it was accidentally or wrongfully deactivated.
Keep in mind that search engines and other third parties may still retain copies of your public information, like your profile information and public uploads, even after you have deleted the information from our services or deactivated your account. Any anonymized data may remain in the system indefinitely. 
</p>

<h5> Object, Restrict, or Withdraw Consent </h5>
<p> When you are logged into your Vizion account, you can manage your privacy settings and other account features here at any time. </p>

<h5> Portability </h5>
<p> Vizion provides you a means to download the information you have shared through our services by following the steps here.
 </p>

<h3> Children And Our Services </h3>
<p> Our services are not directed to children, and you may not use our services if you are under the age of 13. You must also be old enough to consent to the processing of your personal data in your country (in some countries we may allow your parent or guardian to do so on your behalf). 
Vizion shall not be responsible for any claims that may arise from false statement or misrepresentation of age
</p>


<h3> Our Global Operations and Privacy Shield </h3>

<p> To bring you our services, we operate globally. Where the laws of your country allow you to do so, you authorize us to transfer, store, and use your data in the United States, Ireland, and any other country where we operate. In some of the countries to which we transfer personal data, the privacy and data protection laws and rules regarding when government authorities may access data may vary from those of your country. Learn more about our global operations and data transfer here.
When we transfer personal data outside of the European Union or EFTA States, we ensure an adequate level of protection for the rights of data subjects based on the adequacy of the receiving country’s data protection laws, contractual obligations placed on the recipient of the data (model clauses may be requested by inquiry as described below), or EU-US and Swiss-US Privacy Shield principles.
Vizion, Inc. complies with the EU-US and Swiss-US Privacy Shield principles (the “Principles”) regarding the collection, use, sharing, and retention of personal data from the European Union and Switzerland, as described in our EU-US Privacy Shield certification and Swiss-US Privacy Shield certification.
If you have a Privacy Shield-related complaint, please contact us here. As part of our participation in Privacy Shield, if you have a dispute with us about our adherence to the Principles, we will seek to resolve it through our internal complaint resolution process, alternatively through the independent dispute resolution body JAMS, and under certain conditions, through the Privacy Shield arbitration process.
Privacy Shield participants are subject to the investigatory and enforcement powers of the US Federal Trade Commission and other authorized statutory bodies. Under certain circumstances, participants may be liable for the transfer of personal data from the EU or Switzerland to third parties outside the EU and Switzerland. Learn more about the EU-US Privacy Shield and Swiss-US Privacy Shield here.


We may revise this Privacy Policy from time to time. The most current version of the policy will govern our processing of your personal data and will always be at https://vizion.app/varyitprivacy. If we make a change to this policy that, in our sole discretion, is material, we will notify you via an @Vizion update or email to the email address associated with your account. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy.
 
</p>

<p> <b> Effetive 09/01/2024 </b> </p>
<p> © 2024 Vizion </p>


 
</div>